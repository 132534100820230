<template>
  <div>
    <div v-if="remaining > 0" class="subtitle-2 minimum-purchase">
      {{ $t("checkout.minimumPurchase", [$n(remaining, "currency")]) }}
    </div>

    <div
      v-if="cart.deliveryFee > 0 && remainingAmountForNextFee > 0"
      class="subtitle-2 minimum-purchase"
    >
      {{
        $t("checkout.remainingAmountForNextFee", [
          $n(remainingAmountForNextFee, "currency"),
          $n(minimumAmountForNextFee, "currency")
        ])
      }}
    </div>
  </div>
</template>
<style scoped lang="scss">
.minimum-purchase {
  color: var(--v-error-base);
}
</style>
<script>
import deliveryReactive from "~/mixins/deliveryReactive";
import DeliveryService from "~/service/deliveryService";
import CartService from "~/service/cartService";

import { mapState, mapGetters } from "vuex";

export default {
  name: "PurchaseMinimum",
  mixins: [deliveryReactive],
  data() {
    return {
      minimumPurchase: null,
      deliveryFee: null,
      minimumAmountForNextFee: 0,
      remainingAmountForNextFee: 0,
      initTotalPrice: 0
    };
  },
  computed: {
    ...mapState({
      cart: ({ cart }) => cart.cart
    }),
    ...mapGetters({
      deliveryServiceId: "cart/getDeliveryServiceId"
    }),
    remaining() {
      return (
        this.minimumPurchase -
        (this.cart.totalPrice - this.cart.totalPriceSubscription)
      );
    }
  },
  methods: {
    async getDeliveryFee() {
      this.deliveryFee =
        this.deliveryServiceId == 2
          ? await DeliveryService.getDeliveryFee()
          : (this.deliveryFee = null);
    },
    async reload() {
      let purchaseRestriction = await CartService.getPurchaseRestriction();
      console.log("dew", purchaseRestriction);
      this.minimumPurchase = purchaseRestriction.minimumPurchase;

      let _this = this;
      await _this.getDeliveryFee();
      let totalPrice = this.cart.totalPrice - this.cart.totalPriceSubscription;
      if (
        _this.deliveryFee != null &&
        this.deliveryFee.deliveryFeeRows != null
      ) {
        for (
          let index = 0;
          index < this.deliveryFee.deliveryFeeRows.length;
          index++
        ) {
          const elementRow = this.deliveryFee.deliveryFeeRows[index];

          if (elementRow.range > this.cart.totalPrice + 0.01) {
            if (index + 1 < this.deliveryFee.deliveryFeeRows.length) {
              _this.remainingAmountForNextFee =
                0.01 + elementRow.range - totalPrice;
              _this.minimumAmountForNextFee = this.deliveryFee.deliveryFeeRows[
                index + 1
              ].fee;
            } else {
              _this.remainingAmountForNextFee = 0;
              _this.minimumAmountForNextFee = 0;
            }
            break;
          }
        }
      }
    }
  },
  async mounted() {
    this.reload();
    this.initTotalPrice = this.cart.totalPrice;
  },
  watch: {
    cart(val, oldVal) {
      if (
        val.totalPrice != oldVal.totalPrice &&
        val.totalPrice != this.initTotalPrice
      ) {
        this.reload();
      }
    }
  }
};
</script>
